<template>
    <div class="container">
        <div class="alert alert-centered rounded-0 mb-3" :class="repairKit.priority ? 'alert-danger' : 'alert-info'" v-if="repairKit && repairKit.in_house">
            <animated-info></animated-info> In-house<span v-if="repairKit.priority"> priority</span> repair
        </div>

        <!-- Print label prompt -->
        <div class="col-12">
            <h2 class="text-center">Scan Repair Kit Label To Confirm</h2>

            <scanner @barcodeHasBeenScanned="confirmRepairKitLabel"></scanner>

            <div class="text-center d-flex mt-4 px-3 justify-between">
                <select name="pickingdesk" id="pickingdesk" class="form-control w-50 ml-2 mr-5" v-model="selectedPickingDesk" @change="setDesk($event)">
                    <option :value="null">Select a picking desk</option>
                    <option v-for="(pickingDesk, index) in pickingDesks" :key="index" :value="pickingDesk.id">{{pickingDesk.title}}</option>
                </select>
                <button class="btn btn-info btn-lg mx-auto btn-block w-50 py-1 mx-4" @click="printRepairKitLabel({repairKitId: repairKitId, deskId: selectedPickingDesk})" :disabled="!selectedPickingDesk">{{ printButtonText }}</button>
            </div>
            <div class="px-3">
                <a href="#!" class="refresh text-muted" @click="refreshDesks()">{{ refreshText }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "RepairKitLabel",

    props:['repairKitId'],

    data() {
        return {
            selectedPickingDesk: null,
            loading: false,
            refreshing: false
        }
    },

    computed: {
        ...mapGetters([
            'repairKit',
            'pickingDesks'
        ]),

        printButtonText() {
            if (this.repairKit && this.repairKit.label_printed) {
                return "Re-print Label";
            }
            return "Print Label";
        },

        refreshText() {
            if (this.refreshing) {
                return 'Refreshing Picking Desks...';
            }
            return 'Refresh Picking Desks'
        }
    },

    watch: {
        repairKit(kit) {
            if (kit && kit.currently_picking) {
                this.displayToast({text: "This kit is currently being picked.", type: 'error', duration: 5000});
            }
        }
    },

    methods: {
        ...mapActions([
            'displayToast',
            'loadRepairKit',
            'logToApi',
            'getPickingDesks',
            'printRepairKitLabel'
        ]),

        setDesk(event) {
            localStorage.removeItem('selectedPickingDesk');
            localStorage.setItem('selectedPickingDesk', event.target.value);
        },

        refreshDesks() {
            if (this.refreshing) return;

            this.refreshing = true;
            this.selectedPickingDesk = null;

            this.getPickingDesks().then(() => {
                this.refreshing = false;
            });

        },

        confirmRepairKitLabel(barcodeScanned) {
            if (this.repairKit.id != barcodeScanned.id) {
                this.displayToast({text: "This is not the correct label for this repair kit.", type: 'error'});

                return;
            }
            this.logToApi({action: 'repair_kit_picking', event: 'start', repairKitId: this.repairKit.id});

            this.$router.push({name: 'repairKitPicking.repairKit', params: {repairKitId: this.repairKit.id}});
        }

    },

    mounted() {
        this.selectedPickingDesk = localStorage.selectedPickingDesk;
        this.loadRepairKit({
            repairKitId: this.repairKitId
        });
        if (!this.pickingDesks.length) {
            this.refreshDesks();
        }
    },

}
</script>

<style scoped>
.refresh {
    margin-top: 20px;
    display: block;
    padding-left: 15px;
    color: #fff !important;
}
.refresh:hover {
    color: #E3E3E3 !important;
}
.refresh:hover::after{
    display: none !important;
}
</style>